import textFadeIn from "./text-fadein";

const scrollTrigger = document.querySelectorAll(".js-scroll-trigger");
const scrollTriggerArray = Array.prototype.slice.call(scrollTrigger, 0);

const options = {
	root: null,
	rootMargin: "0px 0px -10%",
	threshold: 0,
};
const observer = new IntersectionObserver(doWhenIntersect, options);
scrollTriggerArray.forEach(function(scrollTrigger) {
	observer.observe(scrollTrigger);
});

/**
 * 交差したときに呼び出す関数
 * @param entries
 */
function doWhenIntersect(entries) {
	const entriesArray = Array.prototype.slice.call(entries, 0);
	entriesArray.forEach(function(entry) {
		if (entry.isIntersecting) {
			entry.target.classList.add("is-active");
			textFadeIn(); //各見出しのフェードイン
		}
	});
}
