const mainSlider = document.querySelector(".js-swiper-mv");
const mainOption = {
	speed: 3000,
	slidesPerView: "1",
	loop: true,
	loopAdditionalSlides: 0,
	preventInteractionOnTransition: true,
	autoplay: {
		delay: 3000,
		disableOnInteraction: false,
	},
	effect: "fade",
	fadeEffect: {
		crossFade: true,
	},

	pagination: {
		el: ".swiper-pagination",
		clickable: true,
		type: "bullets",
		renderBullet: (index, className) => {
			return (
				'<span class="' +
				className +
				'">' +
				'<span class="swiper-circle"></span>' +
				'<svg class="circle" width="40" height="40" viewBox="0 0 48 48"><circle class="circle2" cx="23.5" cy="23.5" r="23" stroke="transparent" stroke-width="1" fill="none"/><circle class="circle1" cx="24" cy="24" r="23" stroke="#fff" stroke-width="1.5" fill="none"/></svg></span>'
			);
		},
	},
	on: {
		slideChange: (swiper) => {
			if (swiper.realIndex > 0) {
				swiper.params.autoplay.delay = 0;
			}
		},
	},
};

export { mainSlider, mainOption };
