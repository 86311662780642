document.addEventListener("DOMContentLoaded", function() {
	const smoothScrollTrigger = document.querySelectorAll('a[href^="#"]');
	for (let i = 0; i < smoothScrollTrigger.length; i++) {
		// console.log(smoothScrollTrigger[i]);
		smoothScrollTrigger[i].addEventListener("click", (e) => {
			e.preventDefault();
			let href = smoothScrollTrigger[i].getAttribute("href");
			let targetElement = document.getElementById(href.replace("#", ""));

			// console.log(targetElement);
			const targetElementId = targetElement.getAttribute("id");
			// console.log(targetElementId);

			const rect = targetElement.getBoundingClientRect().top;
			// const offset = window.pageYOffset;
			const mainPadding = getComputedStyle(document.querySelector(".l-main"));
			const headerHeight = mainPadding.getPropertyValue("--header-height");
			// const headerHeightResult = parseInt(headerHeight);
		});
	}
});

// import $ from "jquery";
// // スムーススクロール
// $(function() {
//   $("a").click(function() {
//     let viewportWidth = document.documentElement.clientWidth;
//     console.log(viewportWidth);

//     let adjust = 0;
//     let speed = 400;
//     let href = $(this).attr("href");

//     var target = $(href == "#" || href == "" ? "html" : href);
//     var position = target.offset().top - adjust;
//     $("body,html").animate({ scrollTop: position }, speed, "swing");
//     return false;
//   });
// });
