const main = document.querySelector(".c-page-image");
const coverFirst = document.querySelector(".c-page-image__cover.-first");
const coverLast = document.querySelector(".c-page-image__cover.-last");
const pic = document.querySelector(".c-page-image__picture");

document.addEventListener("DOMContentLoaded", () => {
	if (main) {
		main.style.opacity = "1";
		coverFirst.classList.add("is-active");
		coverLast.classList.add("is-active");
		pic.classList.add("is-active");
	}
});
