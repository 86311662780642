!(function() {
	const viewport = document.querySelector('meta[name="viewport"]');
	function switchViewport() {
		const large = "width=1280";
		const small = "width=375";
		const windowWidth = window.outerWidth;
		const defaultWidth = "width=device-width,initial-scale=1";
		// if (windowWidth >= 1280) {
		// 	viewport.setAttribute("content", large);
		// }
		// if (windowWidth <= 1280 && windowWidth >= 375) {
		// 	viewport.setAttribute("content", defaultWidth);
		// }
		if (windowWidth <= 375) {
			viewport.setAttribute("content", small);
		} else {
			viewport.setAttribute("content", defaultWidth);
		}
	}
	addEventListener("resize", switchViewport, false);
	switchViewport();
})();
