function textFadeIn() {
	const mains = [...document.querySelectorAll(".c-top-title__main")];
	mains.forEach((main) => {
		if (main.classList.contains("is-active")) {
			const childs = [...main.children];
			setTimeout(() => {
				childs.forEach((child, index) => {
					setTimeout(() => {
						child.classList.add("is-active");
					}, 100 * index);
				});
			}, 500);
		}
	});
}

export default textFadeIn;
