const loadingFadeIn = (value) => {
	// console.log(value);
	const first = document.querySelector(".p-main-visual__copy.-first");
	const firstBg = document.querySelector(
		".p-main-visual__copy.-first .-background"
	);
	const second = document.querySelector(".p-main-visual__copy.-second");
	const secondBg = document.querySelector(
		".p-main-visual__copy.-second .-background"
	);
	const copies = [...document.querySelectorAll(".p-main-visual__copy")];

	new Promise((resolve) => {
		resolve();
	})
		.then(() => {
			if (value == 1) {
				firstBg.classList.add("-white");
				secondBg.classList.add("-white");
			} else {
				firstBg.classList.add("-beige");
				secondBg.classList.add("-beige");
			}
		})
		.then(() => {
			if (value == 1) {
				setTimeout(() => {
					first.classList.add("fade-in");
				}, 800);
				setTimeout(() => {
					second.classList.add("fade-in");
				}, 1400);
			} else {
				setTimeout(() => {
					first.classList.add("fade-in");
				}, 900);
				setTimeout(() => {
					second.classList.add("fade-in");
				}, 1500);
			}
		})
		.then(() => {
			setTimeout(() => {
				copies.forEach((copy) => {
					copy.classList.add("is-active");
				});
			}, 600);
		});
};

export default loadingFadeIn;
