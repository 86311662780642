const btnLine = document.querySelector(".c-hamburger__line");
const btnLineLeft = document.querySelector(".c-hamburger__line-left");
const btnLineRight = document.querySelector(".c-hamburger__line-right");

function hamburgerButton(value) {
  if (value == true) {
    btnLine.style.opacity = "0";
    btnLineLeft.classList.add("is-active");
    btnLineRight.classList.add("is-active");
  } else {
    btnLine.style.opacity = "1";
    btnLineLeft.classList.remove("is-active");
    btnLineRight.classList.remove("is-active");
  }
}

export default hamburgerButton;
