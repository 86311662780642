import { Splide } from "@splidejs/splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import "@splidejs/splide/dist/css/splide.min.css";

const InstagramFeed = () => {
	const target = {
		num: 14,
	};

	const accessToken =
		"EAA5oVV27ZApABO0gyV7jXKZAK8ZCatvo67c0ZAvEBsdIgZAVqPiWzqfmotetO787BIZCxQ5BPyq3klLS96D0DxMGaBlO3VVITvdCZC19VETs2jhskwJ3fin6aSXxIZAC0NQVpECdIUxBHgS9aQOGjGXnBqdLMFgNBIIGe4NU8LILlXuKv2FDjecqmcysdmykFpUDdkYe2PUZD";
	const url = `https://graph.facebook.com/v5.0/17841403474501873?fields=name,media.limit(${target.num}){caption,like_count,media_url,permalink,thumbnail_url}&access_token=${accessToken}`;

	fetch(url)
		.then((response) => {
			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}
			return response.json();
		})
		.then((json) => {
			const ig = json.media.data;
			let html = "";
			for (let i = 0; i < ig.length; i++) {
				const caption = ig[i].caption;
				const link = ig[i].permalink;
				const image = ig[i].media_url;
				html += `<li class="splide__slide">
                    <a href="${link}" class="p-instagram__item">
                      <figure>
                        <img alt="" src="${image}" alt="${caption}">
                      </figure>
                    </a>
                  </li>`;
			}
			document.querySelector(".splide__list").innerHTML = html;

			// Splideの初期化
			const splide = new Splide(".splide", {
				type: "loop",
				autoWidth: true, // スライドの幅を自動的に調整
				gap: "30px", // スライド間のギャップ
				pagination: false, // ページネーションを無効にする
				arrows: false,
				breakpoints: {
					576: {
						gap: "1rem",
					},
					768: {
						gap: "1.5rem",
					},
					1024: {
						gap: "2rem",
					},
				},
				autoScroll: {
					speed: 0.6, // スクロール速度
					pauseOnHover: false, // カーソルが乗ってもスクロールを停止させない
				},
			});

			// Splideのインスタンスをマウント
			splide.mount({ AutoScroll });

			// autoScrollの速度をコンソールに表示
			// console.log("Splide autoScroll speed:", splide.options.autoScroll.speed);
		})
		.catch((error) => {
			console.error(
				"An error occurred while fetching Instagram images:",
				error
			);
		});
};

export default InstagramFeed;
