import MicroModal from "micromodal";
import hamburgerButton from "./hamburger-button";

MicroModal.init({
	disableScroll: true,
	awaitOpenAnimation: true,
	awaitCloseAnimation: true,
});

// ハンバーガーメニュー用
const btn = document.querySelector("#js-buttonHamburger");
if (btn) {
	btn.addEventListener("click", () => {
		if (btn.ariaExpanded == "false") {
			btn.ariaExpanded = true;

			hamburgerButton(true);

			MicroModal.show("g-navi-modal", {
				disableScroll: true, // ページスクロールを無効に
				awaitOpenAnimation: true, // 開閉時のアニメーションを可能に
			});

			const linkBtn = [...document.querySelectorAll("#g-navi-modal a")];
			console.log(linkBtn);
			if (linkBtn) {
				linkBtn.forEach((link) => {
					link.addEventListener("click", () => {
						btn.ariaExpanded = false;
						MicroModal.close("g-navi-modal", {
							awaitCloseAnimation: true,
						});
						hamburgerButton(false);
					});
				});
			}
		} else {
			btn.ariaExpanded = false;

			MicroModal.close("g-navi-modal", {
				awaitCloseAnimation: true,
			});
			hamburgerButton(false);
		}
	});
}
