import loadingFadeIn from "./loading-fadein";
import InstagramFeed from "./instagram-feed";
import Swiper from "swiper/bundle";
import "swiper/css/bundle";
import { mainSlider, mainOption } from "./swiper-progressbar";

// セッションストレージのキーと値を定義
const keyName = "visited";
const keyValue = true;

// DOM要素を取得
const loading = document.getElementById("js-loading");
const logoFadeIn = document.querySelector(".p-loading__content");
const logoFadeOut = document.querySelector(".p-loading__picture");
const menuLarge = document.querySelector(".p-navi.-large");
const menuSmall = document.querySelector(".p-navi.-modal");
const ctaButton = document.querySelector(".p-request-cta");
const body = document.getElementById("js-body");
const scrollLine = document.querySelector(".c-scrolldown");
const fadePic = document.querySelector(".p-main-visual__picture");
const windowWidth = window.outerWidth;

// DOMが完全に読み込まれた後にスクリプトを実行
document.addEventListener("DOMContentLoaded", () => {
	if (loading) {
		// 初回訪問かどうかをチェック
		if (!sessionStorage.getItem(keyName)) {
			sessionStorage.setItem(keyName, keyValue);
			loading.style.display = "block";

			// 非同期処理を順番に実行
			new Promise((resolve) => setTimeout(resolve, 700))
				.then(() => {
					logoFadeIn.classList.add("is-active");
					body.style.overflow = "hidden";
				})
				.then(() => {
					setTimeout(() => {
						logoFadeOut.classList.add("is-fade-out");
					}, 3000);
				})
				.then(() => {
					setTimeout(() => {
						logoFadeOut.style.display = "none";
					}, 3800);
				})
				.then(() => {
					setTimeout(() => {
						loading.classList.add("loaded");
					}, 4400);
				})
				.then(() => {
					setTimeout(() => {
						loadingFadeIn();
					}, 4600);
				})
				.then(() => {
					setTimeout(() => {
						fadePic.classList.add("is-active");
					}, 7000);
				})
				.then(() => {
					setTimeout(() => {
						body.style.overflow = "auto";
						loading.remove();
						menuLarge.classList.add("fade-in");
						ctaButton.classList.add("is-active");
						scrollLine.classList.add("is-active");
						InstagramFeed();
						const mvSlider = new Swiper(mainSlider, mainOption);
						if (windowWidth <= 576) {
							menuSmall.classList.add("fade-in");
						}
					}, 9000);
				});
		} else {
			// 初回訪問でない場合の処理
			loadingFadeIn(1);
			loading.remove();
			setTimeout(() => {
				fadePic.classList.add("is-active-default");
			}, 2400);
			setTimeout(() => {
				scrollLine.classList.add("is-active");
				ctaButton.classList.add("is-active");
				menuLarge.classList.add("fade-in");
				InstagramFeed();
				const mvSlider = new Swiper(mainSlider, mainOption);
				window.addEventListener("resize", () => {
					mvSlider.destroy(false, true);
					InstagramFeed();
					new Swiper(mainSlider, mainOption);
				});
				if (windowWidth <= 576) {
					menuSmall.classList.add("fade-in");
				}
			}, 3000);
		}
	} else {
		// ローディング要素が存在しない場合の処理
		InstagramFeed();

		if (windowWidth <= 576) {
			menuSmall.classList.add("fade-in");
		} else {
			menuLarge.classList.add("fade-in");
		}
	}
});
